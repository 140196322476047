<template>
  <div class="home">
    <div class="bg flex-center">
      <div>
        <div>
          <span class="name">点变家</span>
          - 互联网全屋整装方案供应商
        </div>
        <div class="t2">
          全屋整装 • 装修翻新 • 新房装修 • 老房改造 
        </div>
      </div>
    </div>

    <div class="solution-block">
      <div class="title">装修方案服务</div>
      <div class="content clearfix">
        <div class="item">
          <div  style="width:300px;height:150px;"><img src="../assets/case1.jpg" style="width:100%;height:100%;" ></div>
          <div class=" name" >全屋整装</div>
          <div class="des">整套装修方案，让您省时省心</div>
        </div>
        <div class="item">
          <div  style="width:300px;height:150px;"><img src="../assets/nav2.jpg"  style="width:100%;height:100%;" alt=""></div>
          <div class="name">装修翻新</div>
          <div class="des">不满意原装修？海量图片，自由搭配</div>
        </div>
        <div class="item">
          <div  style="width:300px;height:150px;"><img   src="../assets/nav3.jpg" style="width:100%;height:100%;"  alt=""></div>
          <div class="name">新房装修</div>
          <div class="des">全定制化装修方案，各种风格任您选</div>
        </div>
        <div class="item">
          <div  style="width:300px;height:150px;"><img src="../assets/nav4.jpg" style="width:100%;height:100%;" alt=""></div>
          <div class="name">老房改造</div>
          <div class="des">可对原有结构优化，让您老房胜新家</div>
        </div>
      </div>
    </div>

    <div class="about-block">
      <h4>不大不小，刚刚好</h4>
      <h5>点变家希望成为您最靠谱的装修方案供应商</h5>
      <ul class="clearfix">
        <li>
          <div class="value"><span class="count">5</span> 年</div>
          <div class="key">互联网+装修方案服务</div>
        </li>
        <li>
          <div class="value"><span class="count">100</span> 名</div>
          <div class="key">志同道合的队员</div>
        </li>
        <li>
          <div class="value"><span class="count">50+</span> 地</div>
          <div class="key">城市，覆盖合肥</div>
        </li>
        <li>
          <div class="value"><span class="count">100<span class="plus">+</span></span>个</div>
          <div class="key">全套装修方案</div>
        </li>
      </ul>
    </div>

    <div class="case-block classic-case-block" id="case-block">
      <div class="title">100+ 案例成功交付见证</div>
      <div class="content flex flex-wrap ">
          <div class="bd c-pointer" v-for="(item,index) in caseDetailList" :key="index" >
    <div class="img">
      <img class="img-wrap lazyimg_img" :src="item.listPic" />
    </div>
    <div class="info">
      <div class="name lazytext  lazytext_loaded">{{item.caseName}}</div>
    </div>
  </div>
      </div>
    </div>

    <div class="advantage-block">
      <div class="title">流程专业化，交付更保证</div>
      <div class="content">
        <ul>
          <li>
            <div class="img">
              <el-image class="el-img" src="https://www.yunlankeji.com/resources/images/icon-cpsj-wjxq.png"   alt="" />
            </div>
            <div class="name">用心的方案策划</div>
            <div class="desc">针对不同房屋信息，提供不同的解决方案</div>
          </li>
          <li>
            <div class="img">
              <el-image class="el-img" src="https://www.yunlankeji.com/resources/images/icon-cpsj-ts.png"  alt="" />
            </div>
            <div class="name">贴合的室内设计</div>
            <div class="desc">根据您的使用需求、偏好风格，进行定制化设计</div>
          </li>
          <li>
            <div class="img">
              <el-image class="el-img" src="https://www.yunlankeji.com/resources/images/icon-cpsj-ld.png"  alt="" />
            </div>
            <div class="name">规范的施工流程</div>
            <div class="desc">规范、安全、标准化的施工</div>
          </li>
          <li>
            <div class="img">
              <el-image class="el-img" src="https://www.yunlankeji.com/resources/images/icon-cpjf.png"  alt="" />
            </div>
            <div class="name">完整的全流程交付</div>
            <div class="desc">客户亲自验收，不满意再整改</div>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      caseList:[],
      newsList:[],
      isLoading:true,
      caseDetailList:[
        {
           listPic:require('../assets/case1.jpg'),
           caseName:'印象西湖5栋全屋整装',
        },
        {
           listPic:require('../assets/case2.jpg'),
           caseName:'合肥万达临湖苑E区18号楼全屋整装',
        },
         {
           listPic:require('../assets/case3.jpg'),
           caseName:'滨湖沁园B区10幢全屋整装',
        },
          {
           listPic:require('../assets/case4.jpg'),
           caseName:'包河花园C区29号楼全屋整装',
        },
          {
           listPic:require('../assets/case5.jpg'),
           caseName:'信达华宇锦绣龙川C区2栋',
        },
          {
           listPic:require('../assets/case6.jpg'),
           caseName:'柏堰雅苑20号楼',
        },
      ]
    }
  },
  name: 'Home',
 
  methods: {
     
  },
  created () {

  }
}

</script>

<style lang="less"  scoped>
.bg {
  width: 100%;
  height: 527px;
  background-repeat: no-repeat;
  background-size: 100% auto !important;
  background: url('https://www.yunlankeji.com/resources/images/banner-index.jpg')
    no-repeat;
  font-size: 64px;
  color: #fff;
}
.name {
    color: #00aeff;
  }
 .t2 {
    margin-top: 20px;
    font-size: 30px;
  }
.title {
  height: 41px;
  font-size: 40px;
  color: #333333;
  line-height: 44px;
  text-align: center;
}
.solution-block {
  background: #f7fafc;
  height: 545px;
  padding: 78px 20px 0;
  text-align: center;
}
.solution-block .content {
  width: 1320px;
  margin: 58px auto 0;
}
.solution-block .item {
  float: left;
  width: 300px;
  height: 360px;
  margin: 0 13px;
  background: #fff;
  // padding: 80px 44px 0;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  box-sizing: border-box;
}
.solution-block .item:hover {
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
}
.solution-block .item .img {
  height: 200px;
  width: 300px;
  line-height: 80px;
  transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  -moz-transform: scale(0.5);
}
.solution-block .item .name {
  margin-top: 36px;
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}
.solution-block .item .des {
  margin-top: 10px;
  font-size: 14px;
  color: #999999;
  line-height: 22px;
}
.about-block {
  height: 640px;
  background: url('../assets/tdgm-bg.jpg') no-repeat 0 center;
  background-size: 100% 100%;
  padding-top: 98px;
  color: #fff;
  text-align: center;
}
.about-block h4,
.about-block h5 {
  font-weight: normal;
}
.about-block h4 {
  font-size: 40px;
  line-height: 44px;
}
.about-block h5 {
  font-size: 18px;
  line-height: 22px;
  margin-top: 34px;
}
.about-block ul {
  width: 1320px;
  margin: 138px auto 0;
}
.about-block ul li {
  float: left;
  width: 25%;
}
.about-block ul li .value {
  font-size: 24px;
}
.about-block ul li .value .count {
  font-size: 110px;
  line-height: 114px;
  vertical-align: bottom;
  font-family: DINCond-Black;
  font-weight: bold;
  color: #00aeff;
}
.about-block ul li .value .count .plus {
  font-size: 55px;
  vertical-align: top;
}
.about-block ul li .key {
  margin-top: 26px;
  font-size: 20px;
  line-height: 24px;
  font-family: PingFang SC;
  color: #d2d5ce;
}

/* // ---------------支持---------------- */

.content {
  width: 1320px;
  margin: 58px auto 0;
}

.case-block {
  margin-top: 50px;
}

.support-block {
  height: 660px;
  background: #f7fafc;
  padding-top: 78px;
  padding-bottom: 40px;
}
.support-block .content {
  width: 1320px;
  margin: 58px auto 0;
}
.support-block .content li {
  width: 400px;
}
.support-block .content li .wrap {
  width: 400px;
  height: 240px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 15px 25px;
  box-sizing: border-box;
  background: url('../assets/jszc1.png') no-repeat;
  background-size: 100% 100%;
  color: #fff;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  cursor: pointer;
}
.support-block .content li .wrap:hover {
  -moz-transform: translateY(-5px);
  -webkit-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}
.support-block .content li dt {
  text-align: left;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 18px;
}

/* // --------------------流程-------------------- */

.advantage-block {
  height: 480px;
  padding-top: 78px;
}
.advantage-block .content {
  width: 1320px;
  margin: 72px auto 0;
}
.advantage-block .content li {
  float: left;
  width: 25%;
  text-align: center;
}
.advantage-block .content li .img {
  margin: 0 auto;
  width: 80px;
  height: 80px;
  line-height: 80px;
  background: #00aeff;
  border-radius: 50%;
}
.advantage-block .content li .img .el-img {
  max-width: 44px;
  width: 44px;
  height: 44px;
  border: 0px;
  background-size: 100%;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  vertical-align: middle;
}
.advantage-block .content li:hover .img .el-img {
  transform: scale(1.2, 1.2);
}
.advantage-block .content li:nth-child(2):hover .img .el-img {
  transform: rotate(360deg);
}
.advantage-block .content li .name {
  width: 100%;
  height: 56px;
  margin-top: 30px;
  padding-top: 28px;
  font-size: 24px;
  line-height: 28px;
  background: url('../assets/icon-cpsyh.png') no-repeat center top;
  background-size: auto 36px;
}
.advantage-block .content li .desc {
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
}

/* // --------------------流程-------------------- */
.news-block {
  background: #f7fafc;
  height: 712px;
  padding-top: 78px;
}
.news-block .content {
  width: 1308px;
  margin: 58px auto 0;
}

/* //--------------------loading------------------ */

.bd {
  position: relative;
  width: 400px;
  height: 290px;
  margin-bottom: 60px;
  box-sizing: border-box;
  margin-right: 40px;
  .img {
    width: 400px;
    height: 250px;
    background: #eeeeee;
    overflow: hidden;
  }
  .info {
    position: relative;
    margin: -45px auto 0;
    width: 250px;
    height: 80px;
    background: white;
    box-shadow: 0px 6px 11px 0px rgba(43, 46, 40, 0.06);
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
  }

  .info .name {
    margin-top: 4px;
    font-size: 24px;
    line-height: 26px;
    // word-break: break-all;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }

  .info .desc {
    margin-top: 7px;
    font-size: 14px;
    color: #999999;
    line-height: 22px;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.bd:nth-child(4n){
  margin: 0 ;
}

.bd .img {
  width: 400px;
  height: 250px;
  background: #eeeeee;
  overflow: hidden;
}

.bd .img .img-wrap {
  width: 100%;
  height: 100%;
  background: #eeeeee;
  /* // background: url('../assets/images/placeholder-logo2.png') no-repeat center
  //   center; */
  background-size: cover;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
}

.bd .img :hover {
  transform: scale(1.2, 1.2);
  -webkit-transform: scale(1.2, 1.2);
  -o-transform: scale(1.2, 1.2);
  -ms-transform: scale(1.2, 1.2);
  -moz-transform: scale(1.2, 1.2);
}

</style>
