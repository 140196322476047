<template>
  <div>
    <!-- <header class="header">
      <el-row :gutter="10">
        <el-col :span="5">
          <div class="flex-center logo">
            <img style="height: 38px" :src="logo" />
          </div>
        </el-col>
        <el-col :span="14">
          <div class="flex-center logo f16">
            <div v-for="(item,index) in menuList" :key="item.id" @click="changeActive(index,item)"
              :class="[active==index? 'blue-b':'' ,   'menu-item', 'textwhite', 'c-pointer']">
              {{item.name}}
            </div>
          </div>
        </el-col>
      </el-row>
    </header> -->
    <main class="content">
      <router-view />
    </main>

    <footer class="footer">
      <div class="content flex justify-center">
        <div class="con-l textl">
          <div class="left1">
            致力于全套的装修方案的设计、施工
          </div>
          <!-- <div class="left2">
            友情链接：<a href="http://aliyun.com/" target="_blank">阿里云</a> <a href="http://www.cobusi.cn/"
              target="_blank">腾讯云</a> <a href="https://cash.yunlankeji.com/" target="_blank">店教授收银</a>
          </div> -->

          <!-- <div class="flex justify-between">
            <el-image class="btn-i mr20" @click="showSendBox" :src="footerB1"></el-image>
            <el-image class="btn-i" @click="showSendBox" :src="footerB2"></el-image>
          </div> -->
        </div>
        <div class="con-r textl">
          <div class="flex align-center">
            <el-image class="f-logo" :src="footerP"></el-image>
             咨询热线：<strong>188-3118-3259</strong>
          </div>
          <!-- <div class="flex align-center em">
            <el-image class="f-logo" :src="footerE"></el-image>
            E-mail : chenxin@yunlankeji.com
          </div> -->
          <div class="flex align-center" style="margin-top:20px;">
            <el-image class="f-logo" :src="footerA"></el-image>
            地址：中国(四川)自由贸易试验区成都高新区天府大道北段1700号4栋1单元11楼1111号
          </div>
        </div>
      </div>
      <!-- <div class="footer_b flex-center">
        <span class="bl">All Rights Reserved</span>
        <span class="br"/>
          <el-image class="ba" :src="footerBa"></el-image>
        <span>皖ICP备18013121号</span>
      </div> -->
    </footer>
  </div>
</template>

<script>
import logo from '@/assets/logo.png'
import footerP from '@/assets/footer-p.png'
import footerA from '@/assets/footer-a.png'
import footerE from '@/assets/footer-e.png'
import footerB2 from '@/assets/footer-b2.png'
import footerB1 from '@/assets/footer-b1.png'
import footerBa from '@/assets/footer-ba.png'
export default {
  data () {
    return {
      dialogVisible:false,
            form:{
              name:'',
              phone:'',
              content:'',
            },
            terminalType:'',
            terminalOs:'',
      logo,
      active: 0,
      footerP,
      footerA,
      footerE,
      footerB1,
      footerB2,
      footerBa,
      menuList: [{ id: 0, name: '主页', path: '/home' },]
    }
  },
  methods: {
    changeActive (index, item) {
      this.active = index
      localStorage.setItem('activeIndex',this.active)
      this.$router.push(item.path)
    },

       getNameFouse(){
         var dom=document.querySelector('.nameItem')
         dom.style.borderBottom='1px solid #409EFF'
      },
      nameFouseOut(){
         var dom=document.querySelector('.nameItem')
         dom.style.borderBottom='1px solid #DCDFE6'
      },
       getPhoneFouse(){
         var dom=document.querySelector('.phoneItem')
         dom.style.borderBottom='1px solid #409EFF'
      },
      phoneFouseOut(){
         var dom=document.querySelector('.phoneItem')
         dom.style.borderBottom='1px solid #DCDFE6'
      },
  },
  created () {
    this.active=localStorage.getItem('activeIndex')
  },
   mounted() {
    if (this.isMobile) {
      this.terminalType = "mobile";
    } else {
      this.terminalType = "pc";
    }
    this.checkEquipmentOS()
  },
}
</script>

<style lang="less" scoped>
.f16 {
  font-size: 16px !important;
}
.c-pointer {
  cursor: pointer;
}
.textwhite {
  color: #fff !important;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 998;
  width: 100%;
  height: 64px;
  padding: 0 32px;
  background: #191a19;
  .logo {
    height: 64px;
  }
  .menu-item {
    height: 63px;
    line-height: 65px;
    padding: 0 15px;
    border-bottom: 3px solid transparent;
  }
  .menu-item:hover {
    color: #00aeff !important;
  }
  .blue-b {
    border-bottom: 3px solid #00aeff;
  }
}
.content {
//   margin-top: 64px;
}
.footer {
  height: 106px; 
  color: #fff;
  background: #2b3233;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    .con-l {
      border-right: 1px solid #fff;
      padding-right: 40px;
      .btn-i {
        width: 160px;
      }
      .left1 {
        font-size: 25px;
      }
      .left2 {
        margin: 20px 0 30px;
        a {
          margin-right: 15px;
        }
      }
    }
    .con-r {
      margin-left: 50px;
      .f-logo {
        margin-right: 12px;
        width: 20px;
        height: 20px;
      }
      .em {
        margin: 15px 0;
      }
    }
  }
  .footer_b {
    font-size: 14px;
    color: #666;
    margin-top: 30px;
    height: 50px;
    border-top: 1px solid #666;
    .bl {
      margin-right: 50px;
    }
    .br {
      .ba {
        margin-right: 5px;
        width: 20px;
        height: 23px;
      }
    }
  }
}
.btn-i:hover{
  cursor: pointer;
}

// 弹框
/deep/ .el-dialog__header{
   padding: 0 ;
}
.title{
    height: 110px;
    padding: 30px 30px 0;
    background: #00AEFF;
    color: #fff;
    display: flex;
    flex-direction: row;
}
.managePhoto{
  width: 80px;
}
.manageDetail{
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.manageName{
    font-size: 24px;
    line-height: 28px;
    margin-top: 8px;
    text-align: left;
}
.tips{
  margin-top: 16px;
  font-size: 16px;
  line-height: 20px;
}
.bodyForm{
  padding: 0 30px;
}
/deep/ .el-dialog__body{
  padding: 0 ;
}
/deep/ .el-form-item__label{
  font-size: 18px;
  line-height: 60px;
  padding: 0 ;
  font-weight: 500;
}
.nameItem{
  border-bottom:1px solid #DCDFE6;
}
.phoneItem{
    border-bottom:1px solid #DCDFE6;
}
/deep/ .el-input__inner{
  height: auto !important;
  align-self: center;
  padding: 10px;
  font-size: 18px;
  border: none !important;
}
/deep/ .el-textarea__inner{
  font-size: 18px;
  height:120px;
  padding: 20px;
}
.footBtn{
    margin-top: 40px;
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    text-align: center;
    font-size: 24px;
    background: #00AEFF;
    color: #fff;
}
/deep/ .el-button{
  padding: 0 !important;
  width: 100%;
}
/deep/ .el-dialog__headerbtn .el-dialog__close{
  color: #fff;
}
/deep/ .el-dialog__headerbtn {
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    width: 30px;
    height: 30px !important;
    top: 0px;
    right: -50px;
}
</style>